import React from "react"
import { colors } from "../config/colors";
import { card } from "../config/colors";

const folderContainer: React.CSSProperties = {
    paddingTop: "2px",
    paddingRight: "2px",
    paddingLeft: "2px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
};

const folder: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    borderRadius: "24px 0 24px 24px",
    backgroundColor: colors.white,
    padding: "20px"
};

const projectPane: React.CSSProperties = {
    overflowY: "auto",
    width: "100%",
    maxHeight: "calc(100vh - 160px)"
};

const dialogContainer: React.CSSProperties = {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "30px"
};

const projectInfo: React.CSSProperties = {
    paddingRight: "15px",
    paddingLeft: "15px",
    overflow: "hidden scroll",
    minWidth: "360px",
};

const dialogTitle: React.CSSProperties = {
    textAlign: "center",
    marginBottom: "13px"
};

const closeButton: React.CSSProperties = {
    backgroundColor: card.declined,
    padding: "7px",
    marginLeft: "8px",
    marginRight: "8px",
    position: "absolute",
    top: "23px",
    right: "5px"
};

const saveButton: React.CSSProperties = {
    backgroundColor: "#E2FFDD",
    padding: "7px",
    marginLeft: "8px",
    color: "#2D861E",
    fontSize: "13px",
    position: "absolute",
    top: "25px",
    right: "50px"
};

const cancelButton: React.CSSProperties = {
    backgroundColor: card.declined,
    padding: "7px",
    marginRight: "3px",
    position: "absolute",
    top: "25px",
    right: "5px"
};

const editButton: React.CSSProperties = {
    backgroundColor: colors.folderTopButton,
    padding: "10px",
    marginLeft: "8px",
    position: "absolute",
    bottom: "25px",
    right: "10px"
};

const modelView: React.CSSProperties = {
    position: "relative",
    flex: 1,
    backgroundColor: "#EBF3FF",
    height: "100%",
    borderRadius: "18px",
};

const fullscreen: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100vh",
    top: "0px",
    left: "0px",
    borderRadius: "0px",
    zIndex: 1000,
};

const fullscreenButton: React.CSSProperties = {
    backgroundColor: colors.fullscreen,
    position: "absolute",
    top: "2px",
    left: "2px",
    padding: "8px",
    zIndex: 5
};

const folderTopContainer: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
};

const folderTop: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    paddingTop: "10px",
    paddingLeft: "0px",
    paddingRight: "20px",
    height: "50px",
    borderRadius: "24px 24px 0 0",
    backgroundColor: colors.white,
    alignSelf: "flex-end",
};

const modelFolderTop: React.CSSProperties = {
    flex: 1,
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "20px",
    height: "50px",
    borderRadius: "24px 24px 0 0",
    backgroundColor: colors.white,
    alignSelf: "flex-end",
};

const topTextContainer: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "15px"
};

export const styles = {
    folderContainer,
    folder,
    projectPane,
    dialogContainer,
    projectInfo,
    dialogTitle,
    closeButton,
    saveButton,
    cancelButton,
    editButton,
    modelView,
    fullscreen,
    fullscreenButton,
    folderTopContainer,
    folderTop,
    modelFolderTop,
    topTextContainer
};
