import { connect } from 'react-redux';
import { showProcessingPane, reportProcessingProgress, showProcessingError } from "../actions/processingActions";
import { toggleHighLodMode } from "../actions/sourceRevitModelsActions";
import {
    getSelectedModelVersion,
    isHighLodModelViewMode,
    isAnalyticsTabVisible,
    State,
    canExposeFacadeModelToPublic,
    canGenerateRevitFacadeModel,
    getCurrentProject
} from "../reducers/mainReducer";
import { createModelSelectionLock } from "./forge/viewer-utils/viewerModelSelectionLock";
import { LoadingExtension, loadViewerExtensions } from "./forgeExtensionsLoader";
import { ForgeViewer, ViewerState } from "./forgeViewer";
import { IModel3DVersion } from "./model3d";
import { IProject } from "./project";

interface IComponentProps {
    project: IProject | null;
    selectedModel: IModel3DVersion | null;
    isHighLodMode: boolean;
    isAnalyticsTabVisible: boolean;
    canExposeFacadeModelToPublic: boolean;
    canGenerateRevitFacadeModel: boolean;

    toggleHighLodMode: (mode: boolean) => void;
    showProcessingPane: (visible: boolean, title?: string) => void;
    reportProcessingProgress: (message: string) => void;
    showProcessingError: (title: string, message: string) => void;
}

export const ForgeHighLodView = (props: IComponentProps) => {
    const extendViewer = async (viewer: Autodesk.Viewing.GuiViewer3D, urn: string, state: ViewerState) => {
        if (!viewer.impl)
            return;

        viewer.setContextMenu(null);

        const modelId = props.selectedModel?.id;

        if (!modelId || state.shutdownSignaled)
            return;

        const renderingExtension = await viewer.loadExtension("Autodesk.NPR") as Autodesk.Extensions.NPR.NPRExtension;

        renderingExtension.setParameter("style", "edging");
        renderingExtension.setParameter("edges", false);
        renderingExtension.setParameter("preserveColor", false);

        const selectionLock = await createModelSelectionLock(viewer, viewer.model)();

        selectionLock.lock();

        const toggleViewMode = () => {
            const model = props.selectedModel;

            if (!model)
                return;

            props.toggleHighLodMode(!props.isHighLodMode);
        }

        try {
            await import("./forge/extensions/index");

            const eventBusModule = await import("./forge/eventBus/eventDispatcher");

            const eventBus = new eventBusModule.EventDispatcher();

            if (props.selectedModel?.urn !== urn || state.shutdownSignaled)
                return;

            const showProcessingPane = (visible: boolean, title?: string) => props.showProcessingPane(visible, title);
            const reportProcessingProgress = (message: string) => props.reportProcessingProgress(message);
            const showProcessingError = (title: string, message: string) => props.showProcessingError(title, message);

            const extensions: LoadingExtension[] = [
                {
                    id: "Dextall.ForgeViewerModeSwitcherExtension",
                    loadOptions: {
                        eventBus,
                        toggleViewMode,
                        buttonIconClass: "viewer-editor-toggle-editor-mode",
                        buttonTooltip: "Switch to panels editor"
                    }
                },
                {
                    id: "Dextall.ForgeInteractiveBomExtension",
                    loadOptions: {
                        modelId,
                        highLodMode: true,
                        eventBus
                    }
                },
                {
                    id: "Dextall.ForgeHighLodPanelModelsExtension",
                    loadOptions: {
                        modelId,
                        showProcessingPane,
                        reportProcessingProgress,
                        showProcessingError,
                        eventBus
                    }
                },
                {
                    id: "Dextall.ForgeRevitFacadeModelExtension",
                    loadOptions: {
                        modelId,
                        canExposeFacadeModelToPublic: props.canExposeFacadeModelToPublic,
                        canGenerateRevitFacadeModel: props.canGenerateRevitFacadeModel,
                        showProcessingPane,
                        reportProcessingProgress,
                        eventBus
                    }
                },
                {
                    id: "Dextall.ForgeLinkedModelsExtension",
                    loadOptions: {
                        modelId,
                        projectId: props.project?.id || "",
                        eventBus
                    }
                }
            ];

            const loadExtensions = loadViewerExtensions.bind(null, props);

            await loadExtensions(viewer, extensions, urn, state);
        } catch (ex) {
            console.error("Failed to extend the viewer in the high LOD mode", ex);
        }
    }

    return (<>
        {props.selectedModel?.urn && <ForgeViewer
            id={props.selectedModel.id}
            urn={props.selectedModel.urn}
            analyticsVisible={props.isAnalyticsTabVisible}
            extendViewer={extendViewer}
            cleanup={() => { }}
            immediateCleanup={() => { }}
            hiddenCategories={["Levels", "Windows", "Doors"]}
            defaultExtensions={defaultExtensions}
        />}</>)
}

const defaultExtensions = ["Autodesk.NPR"];

export default connect(function (store: State) {
    return {
        project: getCurrentProject(store),
        selectedModel: getSelectedModelVersion(store),
        isHighLodMode: isHighLodModelViewMode(store),
        isAnalyticsTabVisible: isAnalyticsTabVisible(store),
        canExposeFacadeModelToPublic: canExposeFacadeModelToPublic(store),
        canGenerateRevitFacadeModel: canGenerateRevitFacadeModel(store),
    };
}, { toggleHighLodMode, showProcessingPane, reportProcessingProgress, showProcessingError })(ForgeHighLodView);