import { combineReducers } from 'redux';
import profileReducer, * as profile from './profileReducer'
import { State as ProfileState } from './profileReducer'
import loginReducer, * as uilogin from './loginReducer'
import { State as LoginState } from './loginReducer'
import modelsReducer, * as models from "./modelsReducer";
import { State as ModelsState } from "./modelsReducer";
import projectsReducer, * as projects from "./projectsReducer";
import { State as ProjectsState } from "./projectsReducer";
import uploadModelReducer, * as modelUpload from "./uploadModelReducer";
import { State as UploadModelState } from "./uploadModelReducer";
import processingReducer, * as processing from "./processingReducer";
import { State as ProcessingState } from "./processingReducer";
import applicationUIReducer, * as applicationUI from "./applicationUIReducer";
import { State as ApplicationUIState } from "./applicationUIReducer";
import modelsAnalyticsReducer, * as modelAnalytics from "./modelsAnalyticsReducer";
import { State as ModelAnalyticsState } from "./modelsAnalyticsReducer";
import insulatedGlassUnitsReducer, * as insulatedGlassUnits from "./insulatedGlassUnitsReducer";
import { State as InsulatedGlassUnitsState } from "./insulatedGlassUnitsReducer";
import { ModelWorkflowStatus } from "../components/model3d";
import priceReducer, * as prices from "./priceReducer";
import { State as PriceState } from "./priceReducer";
import modelSaveCycleReducer, * as save from './modelSaveCycleReducer';
import { State as SaveState } from './modelSaveCycleReducer';

export const mainReducer = combineReducers({
    profile: profileReducer,
    login: loginReducer,
    models: modelsReducer,
    modelUpload: uploadModelReducer,
    processing: processingReducer,
    projects: projectsReducer,
    applicationUI: applicationUIReducer,
    modelAnalytics: modelsAnalyticsReducer,
    prices: priceReducer,
    insulatedGlassUnits: insulatedGlassUnitsReducer,
    save: modelSaveCycleReducer
});

export type State = {
    profile: ProfileState;
    login: LoginState;
    models: ModelsState;
    modelUpload: UploadModelState;
    processing: ProcessingState;
    projects: ProjectsState;
    applicationUI: ApplicationUIState;
    modelAnalytics: ModelAnalyticsState,
    prices: PriceState,
    insulatedGlassUnits: InsulatedGlassUnitsState
    save: SaveState;
}

// LOGIN
export const getLoginResults = function (state: State) {
    return uilogin.getLoginResults(state.login);
}

export const canSignIn = function (state: State) {
    return uilogin.canSignIn(state.login);
}

export const getUserName = function (state: State) {
    return uilogin.getUserName(state.login);
}

export const getPassword = function (state: State) {
    return uilogin.getPassword(state.login);
}

export const getUserData = function (state: State) {
    return {
        name: getUserName(state),
        password: getPassword(state)
    }
}

// PROFILE
export const isLoggedIn = function (state: State) {
    return profile.isLoggedIn(state.profile);
}

export const isApplicationAdmin = function (state: State) {
    return profile.isApplicationAdmin(state.profile);
}

export const isStatsAdmin = function (state: State) {
    return profile.isStatsAdmin(state.profile);
}

export const getProfile = function (state: State) {
    return state.profile;
}

export const getApplicationUserName = function (state: State) {
    return profile.getUserName(state.profile);
}

export const isEngineer = function (state: State) {
    return profile.isEngineer(state.profile);
}

export const isBidManager = function (state: State) {
    return profile.isBidManager(state.profile);
}

export const isDextallEmployee = function (state: State) {
    return profile.isDextallEmployee(state.profile);
}

export const areUnreleasedFeaturesAvailable = function (state: State) {
    return profile.areUnreleasedFeaturesAvailable(state.profile);
}

export const canExposeFacadeModelToPublic = function (state: State) {
    return profile.canExposeFacadeModelToPublic(state.profile);
}

export const canGenerateRevitFacadeModel = function (state: State) {
    return profile.canGenerateRevitFacadeModel(state.profile);
}

export const isEditable = function (state: State) {
    return models.getSelectedModelVersion(state.models)?.workflowStatus === ModelWorkflowStatus.Draft || (isEngineer(state) && models.getSelectedModelVersion(state.models)?.workflowStatus === ModelWorkflowStatus.EngineerDraft);
}

// PROCESSING
export const isProcessingPaneVisible = function (state: State) {
    return processing.isProcessingPaneVisible(state.processing);
}

export const getProcessingMessage = function (state: State) {
    return processing.getProcessingMessage(state.processing);
}

export const getProcessingTitle = function (state: State) {
    return processing.getProcessingTitle(state.processing);
}

export const isProcessingFailed = function (state: State) {
    return processing.isProcessingFailed(state.processing);
}

export const getProcessingFailure = function (state: State) {
    return processing.getProcessingFailure(state.processing);
}

export const getProcessingFailureTitle = function (state: State) {
    return processing.getProcessingFailureTitle(state.processing);
}

// MODELS
export const getModels = function (state: State) {
    return models.getModels(state.models);
}

export const getSelectedModel = function (state: State) {
    return models.getSelectedModel(state.models);
}

export const getSelectedModelVersion = function (state: State) {
    return models.getSelectedModelVersion(state.models);
}

export const isHighLodModelViewMode = function (state: State) {
    return models.isHighLodModelViewMode(state.models);
}

export const getSelectedModelDownloadUrl = function (state: State) {
    return models.getSelectedModelDownloadUrl(state.models);
}

export const getEngineeringProductionModels = function (state: State) {
    return models.getEngineeringProductionModels(state.models);
}

export const getSelectedEngineeringProductionModel = function (state: State) {
    return models.getSelectedEngineeringProductionModel(state.models);
}

export const isModelLoading = function (state: State) {
    return models.getIsModelLoading(state.models);
}

export const isEngineeringModelLoading = function (state: State) {
    return models.getIsEngineeringModelLoading(state.models);
}

// MODEL ANALYTICS
export const getModelAnalytics = function (state: State) {
    return modelAnalytics.getModelAnalytics(state.modelAnalytics);
}

export const getRefreshNeeded = function (state: State) {
    return modelAnalytics.getRefreshNeeded(state.modelAnalytics);
}

// MODEL PRICES
export const getMaterialPrices = function (state: State) {
    return prices.getMaterialPrices(state.prices)
}

export const isMaterialTableVisible = function (state: State) {
    return prices.isMaterialTablelVisible(state.prices)
}

export const getPricesLoadingState = function (state: State) {
    return prices.getPricesLoadingState(state.prices);
}

// MODELS UPLOAD
export const isModelUploadDialogVisible = function (state: State) {
    return modelUpload.isModelUploadDialogVisible(state.modelUpload);
}

export const getNewModelCreationError = function (state: State) {
    return modelUpload.getNewModelCreationError(state.modelUpload);
}

// PROJECTS
export const getProjects = function (state: State) {
    return projects.getProjects(state.projects);
}

export const getCurrentProject = function (state: State) {
    return projects.getCurrentProject(state.projects);
}

export const isCurrentProjectInEditMode = function (state: State) {
    return projects.isCurrentProjectInEditMode(state.projects);
}

export const isCurrentProjectInInfoMode = function (state: State) {
    return state.projects.showInfo;
}

export const isCurrentProjectInSettingsMode = function (state: State) {
    return state.projects.showSettings;
}

export const areProjectsInitialized = function (state: State) {
    return projects.areProjectsInitialized(state.projects);
}

// Windows IGU
export const getInsulatedGlassUnits = function (state: State) {
    return insulatedGlassUnits.getInsulatedGlassUnits(state.insulatedGlassUnits);
}

// APP UI FLAGS
export const isModalInfoVisible = function (state: State) {
    return applicationUI.isModalInfoVisible(state.applicationUI);
}

export const getActiveTab = function (state: State) {
    return applicationUI.getActiveTab(state.applicationUI);
}

export const isAnalyticsTabVisible = function (state: State) {
    return applicationUI.isAnalyticsTabVisible(state.applicationUI);
}

// MODEL SAVE CYCLE
export const isSaving = function (state: State) {
    return save.getIsSaving(state.save);
};

export const getModelCycleFailureMessage = function (state: State) {
    return save.getModelCycleFailureMessage(state.save);
};