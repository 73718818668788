import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from "@weave-design/icon-button";
import { CloseMUI } from "@weave-design/icons";
import "./modalHeader.css";

interface IModalHeaderProps {
    title: string;
    onClose?: () => void;
    isCancellable?: boolean;
    icon?: React.ReactNode;
}

const ModalHeader: React.FC<IModalHeaderProps> = ({ title, onClose, isCancellable, icon }) => {
    return (
        <header id="customHeader">
            <div className="customHeaderContent">
                <div className="title">
                    {icon}
                    <Typography
                        className="processingProgressTitle"
                        style={{ fontSize: "inherit", lineHeight: "inherit", paddingLeft: icon ? "8px" : "0" }}
                    >
                        {title}
                    </Typography>
                </div>
                {isCancellable &&
                    <IconButton
                        className="processingProgressCloseButton"
                        icon={<CloseMUI />}
                        onClick={onClose}
                        title=""
                    />}
            </div>
        </header>
    );
};

export default ModalHeader;