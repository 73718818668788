import React, { useRef } from 'react';
import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import { ProcessingIndicator } from "./processingIndicator";
import ModalHeader from './modalHeader';
import { isProcessingPaneVisible, getProcessingMessage, getProcessingTitle, State } from "../reducers/mainReducer";
import merge from "lodash.merge";
import "./processingPane.css";

export interface IProcessingPaneProps {
    isVisible: boolean;
    title: string;
    message: string | null;
    onClose?: () => void;
    isCancellable?: boolean;
}

export const ProcessingPane = (props: IProcessingPaneProps) => {
    const modalStyles = useRef((styles: any) =>
        merge(styles, {
            modal: {
                window: {
                    width: "400px",
                    height: "200px",
                    top: "calc(50% - 100px)"
                },
                bodyContent: {
                    overflow: "hidden"
                }
            }
        }));

    return (<div
        className="dialog">
        <Modal
            open={props.isVisible}
            title={props.title}
            onCloseClick={props.onClose}
            stylesheet={modalStyles.current}
            headerChildren={
                <ModalHeader
                    title={props.title}
                    onClose={props.onClose}
                    isCancellable={props.isCancellable}
                />
            }>
            <ProcessingIndicator message={props.message || ""} />
        </Modal>
    </div>)
}

export default connect(function (store: State) {
    return {
        isVisible: isProcessingPaneVisible(store),
        message: getProcessingMessage(store),
        title: getProcessingTitle(store)
    }
}, {})(ProcessingPane)