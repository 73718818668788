import React from 'react';
import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import Typography from "@weave-design/typography";
import merge from "lodash.merge";
import Button from '@weave-design/button';
import { Error24 } from "@weave-design/icons";
import ModalHeader from './modalHeader';
import { closeProcessingErrorDialog } from "../actions/processingActions";
import { isProcessingFailed, getProcessingFailure, getProcessingFailureTitle, State } from "../reducers/mainReducer";
import './modalFail.css';

interface IComponentProps {
    isVisible: boolean;
    message: string | React.ReactNode | null;
    title: string | null;
    style?: React.CSSProperties;

    closeProcessingErrorDialog: () => void;
}

export const ProcessingFailure = (props: IComponentProps) => {
    if (!props.isVisible)
        return (null);

    const modalStyles = (styles: any) =>
        merge(styles, {
            modal: {
                window: {
                    ...props.style,
                    width: "371px",
                    height: "263px",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "thick",
                    borderLeftColor: "#ec4a41"
                }
            }
        });

    return (<Modal
        open={true}
        title="Error"
        onCloseClick={() => props.closeProcessingErrorDialog()}
        stylesheet={modalStyles}
        headerChildren={
            <ModalHeader
                title="Error"
                onClose={props.closeProcessingErrorDialog}
                icon={<Error24 className="errorIcon" />}
            />
        }>
        <div className="modalFailContent">
            <div>
                <Typography><span className="errorMessageTitle">{props.title}</span></Typography>
                <div className="errorMessage">
                    {props.message}
                </div>
            </div>
        </div>
        <div className="modalFailButtonsContainer">
            <Button className="button" style={
                { width: '102px', height: '36px', borderRadius: '2px', marginLeft: '12px' }}
                type="primary"
                size="small"
                title="Ok"
                onClick={() => props.closeProcessingErrorDialog()}
            />
        </div>
    </Modal>)
}

export default connect(function (store: State) {
    return {
        isVisible: isProcessingFailed(store),
        message: getProcessingFailure(store),
        title: getProcessingFailureTitle(store)
    }
}, { closeProcessingErrorDialog })(ProcessingFailure)